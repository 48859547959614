

















import Vue from 'vue';
import AppNavList from '@/components/app-nav-list.vue';

function isDarkModePreference(): boolean {
  const query = '(prefers-color-scheme: dark)';
  return window.matchMedia(query).matches;
}

function addDarkModePreferenceEventListener(listener: (x: boolean) => void): void {
  const query = '(prefers-color-scheme: dark)';
  window.matchMedia(query).addEventListener('change', (e) => {
    listener(e.matches);
  });
}

export default Vue.extend({
  components: {
    AppNavList,
  },

  data() {
    return {
      isDrawer: false,
    };
  },

  created(): void {
    this.$vuetify.theme.dark = isDarkModePreference();
    addDarkModePreferenceEventListener((isDark) => {
      this.$vuetify.theme.dark = isDark;
    });
  },
});
