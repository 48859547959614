






























import Vue from 'vue';
import CreateOrderBookFields from '@/components/create-order-book-fields.vue';
import OrderBookList from '@/components/order-book-list.vue';
import AppLayout from '@/layouts/app-layout.vue';
import { GQL_ORDER_BOOKS } from '@/graphql/queries';
import { GQL_CREATE_ORDER_BOOK } from '@/graphql/mutations';

export default Vue.extend({
  components: {
    AppLayout,
    CreateOrderBookFields,
    OrderBookList,
  },

  data() {
    return {
      createDialog: {
        loading: false,
        show: false,
      },

      createField: {
        name: '',
      },

      orderBooks: [],
    };
  },

  apollo: {
    orderBooks: {
      query: GQL_ORDER_BOOKS,
      pollInterval: 5000,
    },
  },

  mounted() {
    this.$apollo.queries.orderBooks.refetch();
  },

  methods: {
    cancelCreateDialog() {
      this.resetCreateDialog();
    },

    async doneCreateDialog() {
      try {
        this.createDialog.loading = true;
        const variables = { orderBookName: this.createField.name };
        const response = await this.$apollo.mutate({ mutation: GQL_CREATE_ORDER_BOOK, variables });
        const orderBookId = response.data.createOrderBook;
        await this.$router.push({ name: 'order-book', params: { id: orderBookId } });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        this.resetCreateDialog();
      }
    },

    showCreateDialog() {
      this.createDialog.show = true;
      this.createField.name = '';
    },

    resetCreateDialog() {
      this.createDialog.loading = false;
      this.createDialog.show = false;
      this.createField.name = '';
    },
  },
});
