











import Vue from 'vue';

export default Vue.extend({
  props: {
    loading: Boolean,
    orderBooks: Array,
    route: String,
  },
});
