








import Vue from 'vue';
import AppLayout from '@/layouts/app-layout.vue';

export default Vue.extend({
  components: {
    AppLayout,
  },

  created() {
    this.$router.replace({ name: 'order-books' });
  },
});
